import { ok, err, isOk, isErr } from './utils.js';
import { Type, error } from './type.js';
import { optional } from './optional.js';
import { nullable } from './nullable.js';
// Struct //
////////////
export class StructType extends Type {
    constructor(props) {
        super();
        this.props = props;
    }
    print() {
        return '{ '
            + Object.keys(this.props).map(name => `${String(name)}${isOk(this.props[name].decode(undefined, {})) ? '?' : ''}: ${this.props[name].print()}`).join(', ')
            + ' }';
    }
    checkExtraFields(struct, opts) {
        let errors = [];
        if (opts.unknownFields === 'drop' || opts.unknownFields === 'discard')
            return [];
        for (const p of Object.getOwnPropertyNames(struct)) {
            //if (!this.props.hasOwnProperty(p)) errors.push(`${p}: unknown field`)
            if (!this.props.hasOwnProperty(p))
                errors.push({ path: [p], error: 'unknown field' });
        }
        return errors;
    }
    decode(u, opts) {
        if (typeof u !== 'object' || u === null) {
            return error('expected object');
        }
        const ret = opts.unknownFields === 'discard' ? { ...u } : {};
        const struct = u;
        let errors = [];
        // decode fields
        for (const p in this.props) {
            const res = this.props[p].decode(struct[p], opts);
            if (isOk(res)) {
                ret[p] = res.ok;
            }
            else {
                //errors.push(`${p}: ${res.err}`)
                errors.push(...res.err.map(error => ({ path: [p, ...error.path], error: error.error })));
            }
        }
        // check extra fields
        errors.splice(-1, 0, ...this.checkExtraFields(struct, opts));
        //if (errors.length) return err(errors.join('\n'))
        if (errors.length)
            return err(errors);
        return ok(ret);
    }
    async validate(v, opts) {
        const struct = v;
        let errors = [];
        for (const p in this.props) {
            const res = await this.props[p].validate(struct[p], opts);
            if (isErr(res)) {
                errors.push(...res.err.map(error => ({ path: [p, ...error.path], error: error.error })));
            }
        }
        if (errors.length)
            return err(errors);
        return this.validateBase(v, opts);
    }
}
export function struct(props) {
    return new StructType(props);
}
// Partial //
/////////////
export function partial(strct) {
    const partialProps = {};
    for (const p in strct.props) {
        const type = strct.props[p];
        if (type)
            partialProps[p] = isOk(type.decode(undefined, {})) ? type : optional(type);
    }
    return struct(partialProps);
}
export function patch(strct) {
    const patchProps = {};
    for (const p in strct.props) {
        const type = strct.props[p];
        if (type)
            patchProps[p] = isOk(type.decode(undefined, {})) ? nullable(type) : optional(type);
    }
    return struct(patchProps);
}
// Pick //
//////////
export function pick(strct, keys) {
    const pickProps = {};
    for (const p in strct.props) {
        if (keys.includes(p))
            pickProps[p] = strct.props[p];
    }
    return struct(pickProps);
}
// Omit //
//////////
export function omit(strct, keys) {
    const omitProps = {};
    for (const p in strct.props) {
        if (!keys.includes(p))
            omitProps[p] = strct.props[p];
    }
    return struct(omitProps);
}
// FIXME: deprecated, remove later
export const type = struct;
// vim: ts=4
