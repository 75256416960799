import { isErr } from './utils.js';
import { Type, error } from './type.js';
// TaggedUnion //
/////////////////
//class TaggedUnionType<A, T extends keyof A> extends Type<A[keyof A]> {
//class TaggedUnionType<A extends Record<T, any>, T extends string> extends Type<A[keyof A]> {
class TaggedUnionType extends Type {
    //constructor(tag: T, members: { [K in keyof A]: Type<A[K] & Record<T, K>> }) {
    //constructor(tag: string, members: { [K in keyof A]: Type<A[K]> }) {
    constructor(tag, members) {
        super();
        this.tag = tag;
        this.members = members;
    }
    print() {
        return Object.values(this.members).map(member => member.print()).join(' | ');
    }
    //decode(u: unknown, opts: DecoderOpts): Result<A[keyof A], RTError> {
    decode(u, opts) {
        let errors = [];
        if (typeof u !== 'object' || u === null) {
            return error('expected object');
        }
        const o = u;
        if (!o[this.tag])
            return error(`missing tag ('${this.tag}')')`);
        const member = this.members[o[this.tag]];
        if (!member)
            return error(`unknown tag (${this.tag} = '${o[this.tag]}')`);
        return member.decode(u, opts);
        /*
        const res = member.decode(u, opts)
        if (isOk(res)) {
            return ok(u as any)
        }
        return res
        */
    }
    /*
    async validate(v: A[keyof A], opts: DecoderOpts) {
        const member: any = this.members[v[this.tag as keyof A] as keyof A]
        return member.validate(v, opts)
    }
    */
    async validate(v, opts) {
        const member = this.members[v[this.tag]];
        const res = await member.validate(v, opts);
        return isErr(res) ? res : this.validateBase(v, opts);
    }
}
//export function taggedUnion<A, T extends string>(tag: T, members: { [K in keyof A]: Type<A[K] & Record<T, K>> }): Type<A[keyof A]> {
////export function taggedUnion<A, T extends keyof A>(tag: string, members: { [K in keyof A]: Type<A[K]> }): Type<A[keyof A]> {
/*
export function taggedUnion<A, T extends keyof A>(tag: T, members: { [K in keyof A]: Type<A[K] & Record<T, K>> }): Type<A[keyof A]> {
    return new TaggedUnionType(tag, members)
}
*/
//export function taggedUnion<T extends string>(tag: T): <A>(members: { [K in keyof A]: Type<A[K] & Record<T, K>> }) => Type<A[keyof A]> {
export function taggedUnion(tag) {
    return function (members) {
        return new TaggedUnionType(tag, members);
    };
}
// vim: ts=4
