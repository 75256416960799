import { ok, err, isOk, isErr } from './utils.js';
import { Type } from './type.js';
// Optional //
//////////////
class OptionalType extends Type {
    constructor(type) {
        super();
        this.type = type;
    }
    print() {
        return this.type.print() + ' | undefined';
    }
    decode(u, opts) {
        if (u === undefined)
            return ok(undefined);
        const res = this.type.decode(u, opts);
        return isOk(res) ? res : err(res.err);
    }
    async validate(v, opts) {
        if (v === undefined)
            return ok(undefined);
        const res = await this.type.validate(v, opts);
        return isErr(res) ? res : this.validateBase(v, opts);
    }
}
export function optional(type) {
    return new OptionalType(type);
}
// vim: ts=4
