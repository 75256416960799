export function ok(t) { return { _tag: 'Ok', ok: t }; }
export function err(error) { return { _tag: 'Err', err: error }; }
export function isOk(r) { return r._tag === 'Ok'; }
export function isErr(r) { return r._tag === 'Err'; }
// Other helper funcs //
////////////////////////
export function isEmptyObject(obj) {
    if (typeof obj !== 'object' || obj === null)
        return false;
    for (const k in obj)
        return false;
    return true;
}
// vim: ts=4
