import { ok, isOk } from './utils.js';
import { Type, error } from './type.js';
// Constants //
///////////////
class ConstantType extends Type {
    constructor(value) {
        super();
        this.value = value;
    }
    print() {
        if (this.value === undefined)
            return 'undefined';
        return JSON.stringify(this.value);
    }
    decode(u, opts) {
        if (u !== this.value)
            return error('expected ' + JSON.stringify(this.value));
        return ok(u);
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
}
export const undefinedValue = new ConstantType(undefined);
export const nullValue = new ConstantType(null);
export const trueValue = new ConstantType(true);
export const falseValue = new ConstantType(false);
// String //
////////////
class StringType extends Type {
    print() {
        return 'string';
    }
    decode(u, opts) {
        switch (typeof u) {
            case 'string': return ok(u);
            case 'number': if (opts.coerceNumberToString || opts.coerceScalar || opts.coerceAll)
                return ok('' + u);
        }
        return error('expected string');
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
    // Validators
    in(...list) {
        return this.addValidator((v) => list.indexOf(v) >= 0 ? ok(v)
            : error(`must be one of [${list.map(l => JSON.stringify(l)).join(',')}]`));
    }
    length(minLen, maxLen) {
        if (maxLen == undefined) {
            return this.addValidator((v) => v.length == minLen ? ok(v)
                : error(`length must be ${minLen}`));
        }
        else {
            return this.addValidator((v) => minLen <= v.length && v.length <= maxLen ? ok(v)
                : error(`length must be between ${minLen} and ${maxLen}`));
        }
    }
    minLength(len) {
        return this.addValidator((v) => v.length >= len ? ok(v)
            : error(`length must be at least ${len}`));
    }
    maxLength(len) {
        return this.addValidator((v) => v.length <= len ? ok(v)
            : error(`length must be at most ${len}`));
    }
    matches(pattern) {
        return this.addValidator((v) => pattern.test(v) ? ok(v)
            : error(`must match ${pattern}`));
    }
    email() {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return this.addValidator((v) => pattern.test(v) ? ok(v)
            : error(`must be valid email address`));
    }
}
export const string = new StringType();
// Number //
////////////
class NumberType extends Type {
    print() {
        return 'number';
    }
    decode(u, opts) {
        switch (typeof u) {
            case 'number': if (opts.acceptNaN || !Number.isNaN(u)) {
                return ok(u);
            }
            else
                break;
            case 'string': if (opts.coerceStringToNumber || opts.coerceScalar || opts.coerceAll) {
                if (opts.acceptNaN || !Number.isNaN(+u))
                    return ok(+u);
            }
        }
        return error('expected number');
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
    // Validators
    in(...list) {
        return this.addValidator((v) => list.indexOf(v) >= 0 ? ok(v)
            : error(`must be one of [${list.map(l => JSON.stringify(l)).join(',')}]`));
    }
    integer() {
        return this.addValidator((v) => v === Math.round(v) ? ok(v)
            : error(`must be integer`));
    }
    min(min) {
        return this.addValidator((v) => v >= min ? ok(v)
            : error(`must be at least ${min}`));
    }
    max(max) {
        return this.addValidator((v) => v <= max ? ok(v)
            : error(`must be at most ${max}`));
    }
    between(min, max) {
        return this.addValidator((v) => min <= v && v <= max ? ok(v)
            : error(`must be between ${min} and ${max}`));
    }
}
export const number = new NumberType();
// Integer //
/////////////
class IntegerType extends NumberType {
    print() {
        return 'integer';
    }
    decode(u, opts) {
        const num = number.decode(u, opts);
        if (isOk(num) && Number.isInteger(num.ok))
            return num;
        else
            return error('expected integer');
    }
}
export const integer = new IntegerType();
export const id = new IntegerType();
// Boolean //
/////////////
class BooleanType extends Type {
    print() {
        return 'boolean';
    }
    decode(u, opts) {
        switch (typeof u) {
            case 'boolean': return ok(u);
            case 'number': if (opts.coerceNumberToBoolean || opts.coerceScalar || opts.coerceAll)
                return ok(!!u);
            case 'string': if ((opts.coerceStringToNumber && opts.coerceNumberToBoolean) || opts.coerceScalar || opts.coerceAll)
                return ok(Number.isFinite(+u) ? !!+u : !!u);
        }
        return error('expected boolean');
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
    true() {
        return this.addValidator((v) => v ? ok(v) : error('must be true'));
    }
    false() {
        return this.addValidator((v) => !v ? ok(v) : error('must be false'));
    }
}
export const boolean = new BooleanType();
// Date //
//////////
class DateType extends Type {
    print() {
        return 'Date';
    }
    decode(u, opts) {
        let date;
        switch (typeof u) {
            case 'object':
                if (u instanceof Date && !isNaN(u.valueOf()))
                    return ok(u);
                break;
            case 'string':
                if (opts.coerceStringToDate || opts.coerceDate || opts.coerceAll)
                    date = new Date(u);
                break;
            case 'number':
                if (opts.coerceNumberToDate || opts.coerceDate || opts.coerceAll)
                    date = new Date(u);
                break;
        }
        if (date !== undefined && !isNaN(date.valueOf())) {
            return ok(date);
        }
        else {
            return error('expected date');
        }
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
}
export const date = new DateType();
// Any //
/////////
class AnyType extends Type {
    print() {
        return 'any';
    }
    decode(u, opts) {
        return ok(u);
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
}
export const any = new AnyType();
// Unknown //
/////////////
class UnknownType extends Type {
    print() {
        return 'unknown';
    }
    decode(u, opts) {
        return u != null ? ok(u) : error('expected anything but undefined');
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
}
export const unknown = new UnknownType();
// UnknownObject //
///////////////////
class UnknownObjectType extends Type {
    print() {
        return 'object';
    }
    decode(u, opts) {
        return typeof u === 'object' && u !== null ? ok(u) : error('expected object');
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
}
export const unknownObject = new UnknownObjectType();
function isScalar(u) {
    return typeof u === 'string'
        || typeof u === 'number'
        || typeof u === 'boolean';
}
class LiteralType extends Type {
    constructor(values) {
        super();
        this.values = values;
    }
    print() {
        return this.values.map(v => JSON.stringify(v)).join(' | ');
    }
    decode(u, opts) {
        if (!isScalar(u)
            || !this.values.includes(u))
            return error(`expected ${this.values.map(v => JSON.stringify(v)).join(' | ')}`);
        return ok(u);
    }
    async validate(v, opts) {
        return this.validateBase(v, opts);
    }
    // Validators
    in(...list) {
        return this.addValidator((v) => list.indexOf(v) >= 0 ? ok(v)
            : error(`must be one of [${list.map(l => JSON.stringify(l)).join(',')}]`));
    }
}
export function literal(...values) {
    return new LiteralType(values);
}
// vim: ts=4
