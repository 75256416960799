import { ok, err, isErr } from './utils.js';
export class Type {
    //addValidator(validator: Validator<T>): Type<T> {
    addValidator(validator) {
        //const type = new (this.constructor as { new (): Type<T> })()
        //const type = new (this.constructor as { new (): this })()
        const type = new this.constructor();
        for (const prop in this) {
            type[prop] = this[prop];
        }
        type.validators = this.validators ? [...this.validators, validator] : [validator];
        return type;
    }
    async validateBase(v, opts) {
        for (const valid of this.validators || []) {
            const res = await valid(v);
            if (isErr(res))
                return res;
        }
        return ok(v);
    }
}
export function decode(type, value, opts = {}) {
    return type.decode(value, opts);
}
export async function validate(type, value, opts = {}) {
    const res = type.decode(value, opts);
    if (isErr(res))
        return res;
    return type.validate(res.ok, opts);
}
export function decoderError(path, error) {
    return err([{ path, error }]);
}
export function error(error, path = []) {
    return err([{ path, error }]);
}
// vim: ts=4
