// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as T from '@symbion/runtype';
export const tActionType = T.literal('POST', 'REPOST', 'REACT', 'CMNT', 'FLLW', 'SHRE', 'MSG');
export const tAction = T.struct({
    type: T.string,
    subType: T.optional(T.string),
    parentId: T.optional(T.string),
    rootId: T.optional(T.string),
    issuerTag: T.string,
    audienceTag: T.optional(T.string),
    content: T.optional(T.unknown),
    attachments: T.optional(T.array(T.string)),
    subject: T.optional(T.string),
    createdAt: T.number,
    expiresAt: T.optional(T.number)
});
export const tNewAction = T.struct({
    type: T.string,
    subType: T.optional(T.string),
    parentId: T.optional(T.string),
    rootId: T.optional(T.string),
    audienceTag: T.optional(T.string),
    content: T.optional(T.unknown),
    attachments: T.optional(T.array(T.string)),
    subject: T.optional(T.string),
    expiresAt: T.optional(T.number)
});
export const tActionView = T.struct({
    actionId: T.string,
    type: T.string,
    subType: T.optional(T.string),
    parentId: T.optional(T.string),
    rootId: T.optional(T.string),
    issuer: T.struct({
        tag: T.string,
        name: T.string,
        profilePic: T.optional(T.string)
    }),
    audience: T.optional(T.struct({
        tag: T.string,
        name: T.string,
        profilePic: T.optional(T.string)
    })),
    content: T.optional(T.unknown),
    attachments: T.optional(T.array(T.string)),
    subject: T.optional(T.string),
    createdAt: T.date,
    expiresAt: T.optional(T.number)
});
// Action types //
//////////////////
// User relations
export const tConnectAction = T.struct({
    type: T.literal('CONN'),
    subType: T.undefinedValue,
    content: T.undefinedValue,
    attachments: T.undefinedValue,
    parentId: T.undefinedValue,
    audience: T.undefinedValue,
    subject: T.string
});
export const tFollowAction = T.struct({
    type: T.literal('FLLW'),
    subType: T.undefinedValue,
    content: T.undefinedValue,
    attachments: T.undefinedValue,
    parentId: T.undefinedValue,
    audience: T.undefinedValue,
    subject: T.string
});
// Posts
export const tPostAction = T.struct({
    type: T.literal('POST'),
    subType: T.string,
    content: T.string,
    attachments: T.optional(T.array(T.string)),
    parentId: T.undefinedValue,
    audience: T.optional(T.string),
    subject: T.undefinedValue
});
// Content spreading
export const tRepostAction = T.struct({
    type: T.literal('REPOST'),
    subType: T.undefinedValue,
    content: T.optional(T.string),
    attachments: T.optional(T.array(T.string)),
    parentId: T.string,
    audience: T.optional(T.string),
    subject: T.undefinedValue
});
export const tShareAction = T.struct({
    type: T.literal('SHRE'),
    subType: T.undefinedValue,
    content: T.optional(T.string),
    attachments: T.undefinedValue,
    parentId: T.undefinedValue,
    audience: T.string,
    subject: T.string
});
// Content reactions
export const tCommentAction = T.struct({
    type: T.literal('CMNT'),
    subType: T.undefinedValue,
    content: T.string,
    attachments: T.optional(T.array(T.string)),
    parentId: T.string,
    audience: T.undefinedValue,
    subject: T.undefinedValue
});
export const tReactAction = T.struct({
    type: T.literal('REACT'),
    subType: T.undefinedValue,
    content: T.literal('LOVE'),
    attachments: T.undefinedValue,
    parentId: T.string,
    audience: T.undefinedValue,
    subject: T.undefinedValue
});
export const tReactionStatAction = T.struct({
    type: T.literal('RSTAT'),
    subType: T.undefinedValue,
    content: T.struct({ comment: T.number, reactions: T.array(T.number) }),
    attachments: T.undefinedValue,
    parentId: T.string,
    audience: T.undefinedValue,
    subject: T.undefinedValue
});
// Messages
export const tMessageAction = T.struct({
    type: T.literal('MSG'),
    subType: T.string,
    content: T.string,
    attachments: T.optional(T.array(T.string)),
    parentId: T.undefinedValue,
    audience: T.optional(T.string),
    subject: T.undefinedValue
});
export const tBaseAction = T.taggedUnion('type')({
    // User relations
    CONN: tConnectAction,
    FLLW: tFollowAction,
    // Posts
    POST: tPostAction,
    // Content spreading
    REPOST: tRepostAction,
    SHRE: tShareAction,
    // Content reactions
    CMNT: tCommentAction,
    REACT: tReactAction,
    RSTAT: tReactionStatAction,
    // Messages
    MSG: tMessageAction,
});
// vim: ts=4
